import { BaseResource } from "./base-resource";
import { Profile } from "@/data/profile";

export default class ProfileResource {
  static load(): Promise<Profile> {
    return BaseResource.tryGet(ProfileUrl.profileUrl);
  }
  static update(request: Profile): Promise<any> {
    return BaseResource.tryPut(ProfileUrl.profileUrl, request);
  }
}

enum ProfileUrl {
  profileUrl = "/v1/profile"
}
