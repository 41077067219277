import { Replenishment, ReplenishmentStatus } from "./replenishment";
import { WriteOffFactory, WriteOffStatus, WriteOffType } from "./write-offs";
import { IPagedListRequest } from "eka.core";

export interface IWalletOperation {
  id: string;
  amount: string;
  transactionId: string;
  status: ReplenishmentStatus | WriteOffStatus;
  type: WriteOffType | "replenishment";
  createdAt: string;
  target: string;
  comment: string;
}

export interface IWalletOperationRow {
  status: WriteOffStatus | ReplenishmentStatus;
  transactionId?: string;
  amount: string;
  className: string;
  expanded?: boolean;
  selected?: boolean;
  readonly typeLabel: string;
  readonly viewAmount: string;
  readonly label: string;
  readonly statusClass: string;
}

export function WalletOperationsFactory(
  w: IWalletOperation
): IWalletOperationRow {
  const o: IWalletOperationRow =
    w.type === "replenishment"
      ? new Replenishment({
          id: w.id,
          amount: w.amount,
          status: w.status as ReplenishmentStatus,
          createdAt: w.createdAt
        })
      : (WriteOffFactory({
          id: w.id,
          amount: w.amount,
          status: w.status as WriteOffStatus,
          createdAt: w.createdAt,
          type: w.type as WriteOffType,
          walletNumber: w.target,
          bankCardNumber: w.target,
          phoneNumber: w.target
        }) as IWalletOperationRow);

  o.transactionId = w.transactionId;
  o.expanded = false;
  o.selected = false;

  return o as IWalletOperationRow;
}

export interface IWalletOperationsListRequest extends IPagedListRequest {
  afterDateTime: string;
  beforeDateTime: string;
}

export enum WalletStatus {
  initialization = "initialization",
  active = "active"
}

export interface IWallet {
  id: string;
  amount: string;
  currency: string;
  number: string;
  status: WalletStatus;
  guid: string;
  blockedAt?: string;
}

export class Wallet implements IWallet {
  id: string = "";
  amount: string = "";
  currency: string = "";
  number: string = "";
  status: WalletStatus = WalletStatus.initialization;
  guid: string = "";
  blockedAt?: string;

  constructor(w?: IWallet) {
    if (w) {
      this.id = w.id;
      this.amount = w.amount;
      this.currency = w.currency;
      this.number = w.number;
      this.status = w.status;
      this.guid = w.guid;
      if (w.blockedAt) this.blockedAt = w.blockedAt;
    }
  }
}
