












import { Component, Vue, Prop } from "vue-property-decorator";

export interface INavigationItem {
  title: string;
  name?: string;
  icon?: string;
  visible: Function;
  subNav?: INavigationItem[];
  opened?: boolean;
}

@Component
export default class NavigationComponent extends Vue {
  @Prop() items: INavigationItem[];
  visibleItems: INavigationItem[] = [];

  created() {
    this.setVisibleItems();
  }

  setVisibleItems(): void {
    let visibleItems = this.items.filter(item => item.visible());
    visibleItems = visibleItems.map(item => {
      if (item.subNav) {
        item.subNav = item.subNav.filter(subItem => subItem.visible());
      }
      return item;
    });
    this.visibleItems = visibleItems;
  }

  itemClick(item: INavigationItem): void {
    if (item.name) {
      if (this.$route.name !== item.name) {
        this.$router.push({
          name: item.name
        });
      }
      this.$store.commit("pageKey");
    } else {
      item.opened = !item.opened;
    }
  }
}
