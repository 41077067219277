












import { Component, Vue } from "vue-property-decorator";
import UserSelectLang from "@/components/layouts/user-lang.vue";
import { getCommonStore } from "eka.core";

@Component({
  components: {
    UserSelectLang
  }
})
export default class UserDataHeader extends Vue {
  get userName(): string {
    return this.$store.state.account.profile.firstName || "Неизвестный друг";
  }
  async logout() {
    await this.$store.dispatch("logout");
  }
  get hasLocalesSwitcher(): boolean {
    return true;
  }
  goToProfile(): void {
    this.$router.push({
      name: "profile"
    });
  }
}
