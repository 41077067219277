import {
  BusinessContextCopyrightType,
  PublicBusinessContext
} from "@/data/business-context";
import axios, { AxiosResponse } from "axios";

export default class BusinessContextResource {
  public static loadBusinessContext(): Promise<PublicBusinessContext> {
    return axios
      .get(BusinessContextUrl.businessContext, {
        params: { locale: "ru" }
      })
      .then((response: AxiosResponse<PublicBusinessContext>) => {
        const businessContext = response.data;
        document.title = businessContext.interface.title;
        if (businessContext.interface.favicon) {
          $("#favicon").attr(
            "href",
            `/products/favicon/${businessContext.interface.favicon}`
          );
        }
        if (!businessContext.interface.copyrightType) {
          businessContext.interface.copyrightType =
            BusinessContextCopyrightType.brand;
        }
        localStorage.setItem(
          "businessContext",
          JSON.stringify(businessContext)
        );
        return businessContext;
      });
  }
}

export enum BusinessContextUrl {
  businessContext = "/api/public/businessContext"
}
