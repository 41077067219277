import { BaseResource } from "./base-resource";
import {
  IAuthRequest,
  IAuthResponse,
  AuthStorage,
  IConfirmGetTokenResponse
} from "@/data/auth";
import { publicInstance } from "@/resources/http";

export default class AuthResource {
  static authentication(request: IAuthRequest): Promise<IAuthResponse> {
    return BaseResource.tryPost(AuthUrls.authentication, request);
  }
  static async refreshToken(): Promise<IConfirmGetTokenResponse> {
    //@ts-ignore
    return await publicInstance
      .post(AuthUrls.refreshToken, await AuthStorage.updateTokenRequest())
      .then(r => r.data);
  }
}

enum AuthUrls {
  authentication = "v2/authentication",
  refreshToken = "/authentication/tokens/refresh"
}
