import {
  PublicBusinessContext,
  PublicBusinessContextSkin
} from "@/data/business-context";
import BusinessContextResource from "@/resources/business-context-resource";
import { ActionContext } from "vuex";

export class BusinessContextState {
  state: null | PublicBusinessContext = null;
}

export class BusinessContextModule {
  public state = new BusinessContextState();
  public mutations = {
    setBusinessContext(
      businessContext: BusinessContextState,
      payload: PublicBusinessContext
    ): void {
      businessContext.state = payload;
    }
  };
  public getters = {
    getBusinessContextStyles(
      businessContext: BusinessContextState
    ): { [key: string]: string } {
      if (businessContext.state) {
        const payButtonConfig = businessContext.state.interface.payButtonConfig;
        const { colors } = JSON.parse(payButtonConfig) as {
          colors: { [key: string]: string };
        };
        return colors;
      }
      return {};
    },
    getBusinessContextLogo(businessContext: BusinessContextState): string {
      return businessContext.state && businessContext.state.interface.logo
        ? `/products/logo/${businessContext.state.interface.logo}`
        : "";
    },
    getBusinessContextLogoHeight(
      businessContext: BusinessContextState
    ): number {
      return (
        (businessContext.state && businessContext.state.interface.logoHeight) ||
        32
      );
    }
  };
  public actions = {
    async loadBusinessContext(
      context: ActionContext<BusinessContextModule, any>
    ): Promise<void> {
      await BusinessContextResource.loadBusinessContext().then(
        businessContext => {
          context.commit("setBusinessContext", businessContext);
        }
      );
    }
  };
}
