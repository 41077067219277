import { DateUtils, formatNumber, Option } from "eka.core";

export enum WriteOffType {
  bankCard = "bankCard",
  wallet = "wallet",
  cash = "cash",
  cellular = "cellular"
}

export enum WriteOffStatus {
  initialization = "initialization",
  processing = "processing",
  completed = "completed",
  canceled = "canceled"
}

export enum CellularType {
  beeline = "beeline",
  mts = "mts",
  megafon = "megafon",
  skylink = "skylink",
  matrixMobile = "matrixMobile",
  dive = "dive",
  motiv = "motiv",
  tele2 = "tele2",
  yota = "yota",
  mgts = "mgts"
}

export interface IWriteOff {
  id: string;
  type: WriteOffType;
  amount: string;
  status: WriteOffStatus;
  createdAt: string;
  confirmedAt?: string;
  bankCardNumber?: string;
  walletNumber?: string;
  phoneNumber?: string;
}

export function getWriteOffStatusLabel(status: WriteOffStatus): string {
  switch (status) {
    case WriteOffStatus.initialization:
      return "Инициализация";
    case WriteOffStatus.processing:
      return "В обработке";
    case WriteOffStatus.completed:
      return "Выполнен";
    case WriteOffStatus.canceled:
      return "Ошибка";
  }
}

export function maskedCard(c: string): string {
  return `**${c.slice(-2)}`;
}

export class WriteOff {
  id = "";
  amount = "";
  createdAt = "";
  status = WriteOffStatus.initialization;
  className = "color-error";

  constructor(w?: IWriteOff) {
    if (w) {
      this.id = w.id;
      this.amount = w.amount;
      this.status = w.status;
      this.createdAt = DateUtils.convertDateFromApiToView(
        w.createdAt
      ) as string;
    }
  }
  get label(): string {
    return getWriteOffStatusLabel(this.status);
  }

  get viewAmount(): string {
    return `- ${formatNumber(this.amount, ".", 2)}`;
  }

  get typeLabel(): string {
    return ``;
  }

  get statusClass(): string {
    if (this.status === WriteOffStatus.canceled) return `color-error`;
    return ``;
  }
}

export class CardWriteOff extends WriteOff {
  bankCardNumber = "";
  constructor(w?: IWriteOff) {
    super(w);
    if (w) {
      this.bankCardNumber = w.bankCardNumber as string;
    }
  }
  get typeLabel(): string {
    return `На карту ${maskedCard(this.bankCardNumber)}`;
  }
}

export class WalletWriteOff extends WriteOff {
  walletNumber = "";
  constructor(w?: IWriteOff) {
    super(w);
    if (w) {
      this.walletNumber = w.walletNumber as string;
    }
  }
  get typeLabel(): string {
    return `На кошелек ${maskedCard(this.walletNumber)}`;
  }
}

export class CellularWriteOff extends WriteOff {
  phoneNumber = "";
  constructor(w?: IWriteOff) {
    super(w);
    if (w) {
      this.phoneNumber = w.phoneNumber as string;
    }
  }
  get typeLabel(): string {
    return `На телефон ${maskedCard(this.phoneNumber)}`;
  }
}

export class CashWriteOff extends WriteOff {
  constructor(w?: IWriteOff) {
    super(w);
  }
  get typeLabel(): string {
    return `Наличными`;
  }
}

export function WriteOffFactory(w: IWriteOff) {
  switch (w.type) {
    case WriteOffType.bankCard:
      return new CardWriteOff(w);
    case WriteOffType.wallet:
      return new WalletWriteOff(w);
    case WriteOffType.cellular:
      return new CellularWriteOff(w);
    case WriteOffType.cash:
      return new CashWriteOff(w);
  }
}

export interface IWriteOffCreationRequest {
  amount: string;
  type: WriteOffType;
  bankCardNumber?: string;
  walletNumber?: string;
  userDeviceType: "webBrowser";
}

export class WriteOffForm {
  amount = "";
  type: WriteOffType.bankCard | WriteOffType.wallet = WriteOffType.bankCard;
  bankCardNumber = "";
  walletNumber = "";

  get options(): Option[] {
    return [
      {
        label: "На карту",
        value: WriteOffType.bankCard
      },
      {
        label: "На кошелек",
        value: WriteOffType.wallet
      }
    ];
  }

  get request(): IWriteOffCreationRequest {
    const request = {
      amount: this.amount,
      type: this.type,
      userDeviceType: "webBrowser"
    } as IWriteOffCreationRequest;
    if (this.type === WriteOffType.bankCard)
      request.bankCardNumber = this.bankCardNumber;
    if (this.type === WriteOffType.wallet)
      request.walletNumber = this.walletNumber;
    return request;
  }
}

export interface IWriteOffConfirmRequest {
  id: string;
  code: string;
}
