export class Profile {
  lastName: string;
  firstName: string;
  middleName: string;
  series: string;
  number: string;
  issueDate: string;
  departmentCode: string;
  issuedBy: string;
  registrationAddress: string;
  phone?: string;
  userCode?: string;
  snils?: string;
  email?: string;
  inn?: string;
  activityType?: string;

  constructor(profile?: Profile) {
    this.lastName = (profile && profile.lastName) || "";
    this.firstName = (profile && profile.firstName) || "";
    this.middleName = (profile && profile.middleName) || "";
    this.series = (profile && profile.series) || "";
    this.number = (profile && profile.number) || "";
    this.issueDate = (profile && profile.issueDate) || "";
    this.departmentCode = (profile && profile.departmentCode) || "";
    this.issuedBy = (profile && profile.issuedBy) || "";
    this.registrationAddress = (profile && profile.registrationAddress) || "";
    this.phone = (profile && profile.phone) || "";
    this.snils = (profile && profile.snils) || "";
    this.inn = (profile && profile.inn) || "";
    this.email = (profile && profile.email) || "";
    this.activityType = (profile && profile.activityType) || "";
  }

  toRequest(userCode?: string) {
    let request = {
      lastName: this.lastName,
      firstName: this.firstName,
      middleName: this.middleName,
      series: this.series,
      number: this.number,
      issueDate: this.issueDate,
      departmentCode: this.departmentCode,
      issuedBy: this.issuedBy,
      registrationAddress: this.registrationAddress
    } as Profile;
    if (userCode) {
      request.userCode = this.userCode;
    }
    return request;
  }
}
