













import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";

@Component
export default class LogoComponent extends Vue {
  logoClick(e: MouseEvent) {
    e.preventDefault();
    this.$emit("click");
  }
  @Getter getBusinessContextLogo: string;
  @Getter getBusinessContextLogoHeight: number;
}
