import { ActionContext } from "vuex";
import { Wallet } from "@/data/wallet";
import { Money } from "eka.core";
import WalletResource from "@/resources/wallet";

export class WalletState {
  wallet = new Wallet();
  fetched: boolean = false;
}

export class WalletModule {
  public state = new WalletState();

  public getters = {
    balance: (state: WalletState): Money | null => {
      return state.fetched
        ? {
            amount: +state.wallet.amount,
            currencyCode: state.wallet.currency
          }
        : null;
    }
  };
  public mutations = {
    wallet(state: WalletState, payload: Wallet) {
      state.wallet = new Wallet(payload);
      state.fetched = true;
    }
  };
  public actions = {
    async wallet(context: ActionContext<WalletState, any>): Promise<void> {
      await WalletResource.loadWallet().then(wallets => {
        context.commit("wallet", wallets.items[0]);
      });
    }
  };
}
