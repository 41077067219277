













import { Component, Vue } from "vue-property-decorator";
import { Money as MoneyInterface } from "eka.core";

@Component
export default class BalanceHeaderComponent extends Vue {
  loading: boolean = false;
  get fetched(): boolean {
    return this.$store.state.wallet.fetched;
  }
  get balance(): MoneyInterface | null {
    return this.$store.getters && this.$store.getters.balance
      ? this.$store.getters.balance
      : null;
  }

  loadWallet() {
    this.$store.dispatch("wallet");
  }
  created() {
    this.loadWallet();
  }
}
