export interface PublicBusinessContext {
  code: string;
  name: string;
  domain: string;
  registrationEnabled: boolean;
  mails: PublicBusinessContextMails;
  interface: PublicBusinessContextInterface;
  urls: PublicBusinessContextUrls;
  phones: PublicBusinessContextPhones;
  features: BusinessContextFeature[];
  behaviors: BusinessContextBehavior[];
  skins: PublicBusinessContextSkin[];
  worksheetRules: PublicBusinessContextWorksheetRules;
  locale: string;
  locales: string[];
}

export interface PublicBusinessContextUrls {
  registrationUrl: string;
  cashdeskLandingUrl: string;
  publicSite: string;
  storage: string;
}

export interface PublicBusinessContextMails {
  commonSupportEmail: string;
  cashdeskSupportEmail: string;
}

export interface PublicBusinessContextPhones {
  commonSupportPhones: string;
  cashdeskSupportPhones: string;
  secondSupportPhone: string;
}

export interface PublicBusinessContextInterface {
  title: string;
  brandName: string;
  favicon: string;
  logo: string;
  logoHeight: number;
  copyrightType: BusinessContextCopyrightType;
  supportChat: boolean;
  supportChatScriptSource: string;
  payButtonConfig: string;
}

export interface PublicBusinessContextSkin {
  /**Имя скина */
  name: string;
  /**Главный "брендовый" цвет */
  mainColor: string;
  /**Второй "брендовый" цвет */
  secondaryColor: string;
  /**Цвет основного текста */
  bodyTextColor: string;
  /**Цвет фона кнопки */
  buttonColor: string;
  /**Цвет фона кнопки при наведении */
  buttonHoverColor: string;
  /**Цвет фона кнопки при клике */
  buttonClickColor: string;
  /**Цвет текста кнопки */
  buttonTextColor: string;
  /**Цвет линии прогресс-бара */
  progressColor: string;
  /**Цвет фона прогресс-бара */
  progressBackgroundColor: string;
  /**Цвет фона элемента селектора при наведении */
  selectItemHoverColor: string;
  /**Цвет ссылки */
  hyperlinkColor: string;
  /**Цвет ссылки при наведении */
  hyperlinkHoverColor: string;
}

export interface PublicBusinessContextWorksheetRules {
  sectionWarranty: boolean;
  uploadPaymentContract: boolean;
}

export enum BusinessContextCopyrightType {
  appex = "appex",
  brand = "brand"
}

export enum BusinessContextFeature {
  worksheetSetup = "worksheetSetup", // Возможность редактирования анкеты
  barkerPayoutRulesSetup = "barkerPayoutRulesSetup", // Возможность настройки правил распределения платежей
  fiscalLimit = "fiscalLimit", // Возможность просмотра фискального лимита
  cashDesk = "cashDesk" // Возможность работы с кассами
}

export enum BusinessContextBehavior {
  hotel = "hotel",
  restaurant = "restaurant",
  mfo = "mfo"
}
