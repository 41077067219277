























import { Component, Vue, Watch } from "vue-property-decorator";
import { LayoutPayload, LayoutMutationPayload } from "eka.core";
import BalanceHeader from "../common/balance-header.vue";
import Navigation, {
  INavigationItem
} from "@/components/common/navigation.vue";
@Component({
  components: {
    Navigation,
    BalanceHeader
  }
})
export default class Sidebar extends Vue {
  className: string = "";

  @Watch("$route.name")
  listenRouteName(value: any) {
    this.hideSidebar();
  }

  get sidebar(): boolean {
    return this.$store.getters.sidebar;
  }
  sidebarTouchStart(e: TouchEvent) {
    e.preventDefault();
    this.$store.commit(
      new LayoutMutationPayload({
        data: !this.sidebar,
        type: "sidebar"
      } as LayoutPayload)
    );
    this.$emit("toggle");
  }
  toggleSidebar(): void {
    this.$store.commit(
      new LayoutMutationPayload({
        data: !this.sidebar,
        type: "sidebar"
      } as LayoutPayload)
    );
    this.$emit("toggle");
  }
  hideSidebar(): void {
    this.$store.commit(
      new LayoutMutationPayload({
        data: false,
        type: "sidebar"
      } as LayoutPayload)
    );
  }

  beforeDestroy() {
    this.hideSidebar();
  }

  mounted() {
    window.addEventListener("resize", () => {
      this.hideSidebar();
    });
  }
  logoClick(): void {
    if (this.$route.name !== "wallet-operations-list") {
      this.$router.push({
        name: "wallet-operations-list"
      });
      this.$store.commit("pageKey");
    }
  }
  get items(): INavigationItem[] {
    return [
      {
        name: "wallet-operations-list",
        title: "Операции",
        icon: "action-pay2",
        visible: () => true
      }
      // {
      //   name: "replenishment-list",
      //   title: "Пополнение",
      //   icon: "action-pay2",
      //   visible: () => true
      // },
      // {
      //   name: "withdrawal-list",
      //   title: "Вывод средств",
      //   icon: "action-pay2",
      //   visible: () => true
      // },
      // {
      //   name: "cards",
      //   title: "Мои карты",
      //   icon: "card",
      //   visible: () => true
      // }
      // {
      //   name: "payment-system-list",
      //   title: "Платежные системы",
      //   icon: "card",
      //   visible: () => true
      // }
    ];
  }
}
