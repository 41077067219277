import { BaseResource } from "./base-resource";
import { PagedList } from "eka.core";
import { User } from "@/store/modules/account";
import {
  IWallet,
  IWalletOperation,
  IWalletOperationsListRequest
} from "@/data/wallet";

export default class WalletResource {
  static loadWallet(): Promise<PagedList<IWallet>> {
    return BaseResource.tryGet(WalletUrl.load);
  }
  static loadWalletOperations(
    request: IWalletOperationsListRequest
  ): Promise<PagedList<IWalletOperation>> {
    return BaseResource.tryGet(WalletUrl.loadWalletOperations, request);
  }
  static loadUser(): Promise<User> {
    return BaseResource.tryGet(WalletUrl.user);
  }
}

enum WalletUrl {
  load = "/v1/wallets",
  loadWalletOperations = "/v1/wallets/operations",
  replenishment = "/v1/wallet/replenishments",
  user = "/v1/users/me"
}
