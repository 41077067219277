import {
  axios,
  AxiosInstance,
  getCommonStore,
  LoadingStartsMutationPayload,
  LoadingEndsMutationPayload
} from "eka.core";
import { AuthStorage } from "@/data/auth";
import Store from "../store/index";

axios.defaults.headers["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.headers["X-Referrer-Type"] = "ecom-ui";

let __instance: null | AxiosInstance = null;
const __httpRequestEvent = "httpRequestEvent";

const onRequest = (requestConfig: any) => {
  getCommonStore().commit(new LoadingStartsMutationPayload(__httpRequestEvent));
  const token = AuthStorage.getAccessToken();
  if (token) {
    requestConfig.headers.Authorization = `bearer ${AuthStorage.getAccessToken()}`;
  } else {
    delete requestConfig.headers.Authorization;
  }
  return requestConfig;
};

const onRequestError = async (error: Error): Promise<Error> => {
  return Promise.reject(error);
};

const onResponseSuccess = (response: any) => {
  getCommonStore().commit(new LoadingEndsMutationPayload(__httpRequestEvent));
  return response;
};

function treatmentMessages(error: any): string {
  let errors = Object.values(error);
  let message: string = "";
  for (let i in errors) {
    if (Array.isArray(errors[i])) {
      message += (errors[i] as string[]).toString() + " ";
    }
  }

  return message;
}

const onResponseError = async (error: any) => {
  const originalRequest: any = error.config;
  if (error && error.response) {
    if (error.response.status === 401) {
      if (!originalRequest._retry && AuthStorage.getAccessToken()) {
        originalRequest._retry = true;
        return await AuthStorage.updateTokens()
          .then(() => {
            originalRequest.headers.Authorization = `bearer ${AuthStorage.getAccessToken()}`;
            if (originalRequest.url.indexOf("/api") === 0) {
              originalRequest.url = originalRequest.url.replace("/api", "");
            }
            return axios(originalRequest).catch(e => e);
          })
          .catch((e: Error) => e);
      } else {
        Store.dispatch("logout");
      }
    }
  }
  return Promise.reject(error);
};

export default function http(): AxiosInstance {
  if (__instance) {
    return __instance;
  }
  const instance = axios.create({
    baseURL: "/api",
    headers: {
      Authorization: `bearer ${AuthStorage.getAccessToken()}`
    }
  });

  instance.interceptors.request.use(onRequest, onRequestError);
  instance.interceptors.response.use(onResponseSuccess, onResponseError);

  __instance = instance;

  return instance;
}

export const publicInstance = axios.create({
  baseURL: "/api"
});
