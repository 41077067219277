import {
  BaseResource as EkaBaseResource,
  ErrorHandlingOption,
  ErrorHandlingSettings
} from "eka.core";
import store from "@/store";
import http from "@/resources/http";

// @ts-ignore
export class BaseResource extends EkaBaseResource {
  public static http(): Function {
    return http();
  }

  public static treatmentMessages(error: any): string {
    let errors = Object.values(error);
    let message: string = "";
    for (let i in errors) {
      if (Array.isArray(errors[i])) {
        message += (errors[i] as string[]).toString() + " ";
      }
    }

    return message;
  }

  public static handleError<TResponse>(
    error: any,
    ...errorHandling: ErrorHandlingSettings[]
  ) {
    this.handleErrorDefault(error);
    return Promise.reject(error);
  }

  public static getErrorMessage(
    error: any,
    option?: ErrorHandlingOption
  ): string {
    if (option) {
      if (option.msg) {
        return option.msg;
      }

      if (option.showRawError) {
        if (error) {
          if (
            error.response &&
            error.response.data &&
            error.response.data.detail
          ) {
            return error.response.data.detail;
          } else if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.errors
          ) {
            return this.treatmentMessages(error.response.data.errors);
          } else {
            return error.toString();
          }
        }
      }
    }

    return "Error interacting with server";
  }
}
