import Vue from "vue";
import App from "./App.vue";
//@ts-ignore
import IdleVue from "idle-vue";
import store from "./store";
import router from "./router";
import core from "eka.core";
import { tooltips } from "eka.core";
import logo from "./components/common/logo.vue";
import i18n from "@/i18n/i18n";
import "./registerServiceWorker";
import Clipboard from "v-clipboard";

//@ts-ignore
import VueMasonry from "vue-masonry-css";
import getLocale from "@/utils/getLocale";

Vue.use(core, {
  validateBikUrl: "/api/referenceBank/validateBik/{value}",
  emailSeparator: ",",
  locale: getLocale()
});

Vue.config.productionTip = false;

if (Vue.prototype.coreMode === "production") {
  try {
    // @ts-ignore
    require("eka.core/dist/ecore.css");
  } catch (e) {
    console.log(e);
  }
}

Vue.use(VueMasonry); // Dashboard grid
Vue.use(Clipboard);

Vue.component("logo", logo);

//@ts-ignore
Vue.use(IdleVue, {
  store: store,
  idleTime: process.env.VUE_APP_IDLE_TIMEOUT
});

//@ts-ignore
tooltips();

(window as any).app = new Vue({
  router,
  store,
  // @ts-ignore
  i18n,
  render: h => h(App)
}).$mount("#app");
console.log("%cWelcome to JP-WALLET!", "color:red");
console.log("%cWelcome to JP-WALLET!", "color:blue");
