import { DateUtils, formatNumber } from "eka.core";

export enum ReplenishmentHandler {
  internal = "internal",
  client = "client"
}

export enum ReplenishmentStatus {
  initialization = "initialization",
  processing = "processing",
  completed = "completed",
  failed = "failed"
}

export interface IReplenishment {
  handler?: ReplenishmentHandler;
  completedAt?: string;
  createdAt: string;
  id: string;
  amount: string;
  commissionAmount?: string;
  status: ReplenishmentStatus;
}

export function getReplenishmentStatusLabel(
  status: ReplenishmentStatus
): string {
  switch (status) {
    case ReplenishmentStatus.initialization:
      return "Инициализация";
    case ReplenishmentStatus.processing:
      return "В обработке";
    case ReplenishmentStatus.completed:
      return "Выполнен";
    case ReplenishmentStatus.failed:
      return "Ошибка";
  }
}

export class Replenishment implements IReplenishment {
  handler = ReplenishmentHandler.client;
  completedAt?: string;
  createdAt = "";
  id = "";
  amount = "";
  commissionAmount = "";
  status = ReplenishmentStatus.initialization;
  className = "color-success";

  constructor(r?: IReplenishment) {
    if (r) {
      if (r.handler) this.handler = r.handler;
      this.completedAt = r.completedAt
        ? (DateUtils.convertDateFromApiToView(r.completedAt) as string)
        : undefined;
      this.createdAt = DateUtils.convertDateFromApiToView(
        r.createdAt
      ) as string;
      this.id = r.id;
      this.amount = r.amount;
      this.commissionAmount = r.commissionAmount || "";
      this.status = r.status;
    }
  }
  get label(): string {
    return getReplenishmentStatusLabel(this.status);
  }
  get viewAmount(): string {
    return `+ ${formatNumber(this.amount, ".", 2)}`;
  }
  get typeLabel(): string {
    return `Пополнение`;
  }
  get statusClass(): string {
    if (this.status === ReplenishmentStatus.failed) return "color-error";

    return ``;
  }
}

export interface IReplenishmentCreationRequest {
  amount: string;
  handler: ReplenishmentHandler;
  backUrl: string;
  walletId?: string;
  userDeviceType: string;
}

export enum UserDeviceType {
  webBrowser = "webBrowser"
}

export class ReplenishmentForm implements IReplenishmentCreationRequest {
  amount = "";
  handler = ReplenishmentHandler.internal;
  userDeviceType = UserDeviceType.webBrowser;
  backUrl = `${window.location.origin}/#/wallet/operations?payed=true`;

  constructor(amount = "") {
    this.amount = amount;
  }
}

export interface IReplenishmentPaymentData {
  url: string;
  data: { [key: string]: string };
}

export class ProcessPayment implements IReplenishmentPaymentData {
  url: string;
  data: any;

  constructor(paymentDetails?: IReplenishmentPaymentData) {
    this.url = (paymentDetails && (paymentDetails.url as string)) || "";
    this.data = (paymentDetails && paymentDetails.data) || "";
  }

  submit() {
    let form = document.createElement("form");
    form.setAttribute("method", "post");
    form.setAttribute("action", this.url as string);

    for (let i in this.data) {
      let input = document.createElement("input");
      input.setAttribute("type", "hidden");
      input.setAttribute("name", i);
      input.setAttribute("value", this.data[i]);
      form.appendChild(input);
    }
    //@ts-ignore
    document.querySelector("body").appendChild(form);
    form.submit();
  }
}

export interface IPaymentResponseQuery {
  payed?: boolean;
  errorCode?: string;
  errorText?: string;
}
