








import { Component, Vue, Watch } from "vue-property-decorator";
import baseLayout from "@/components/layouts/base-layout.vue";
import {
  PublicBusinessContext,
  PublicBusinessContextSkin
} from "@/data/business-context";
import { Action, Getter, Mutation } from "vuex-class";

@Component({
  components: { baseLayout }
})
export default class App extends Vue {
  loading: boolean = true;

  get notAuthZone(): boolean {
    if (!this.$route || !this.$route.name) {
      return true;
    }
    return this.$route.meta && this.$route.meta.notAuthZone;
  }

  get sidebar() {
    return this.$store.state.layout.sidebar;
  }

  @Getter getBusinessContextStyles?: { [key: string]: string };
  @Action loadBusinessContext: () => Promise<void>;
  @Action logout: () => Promise<void>;
  @Mutation setBusinessContext: (payload: PublicBusinessContext) => void;

  beforeCreate() {
    const params = new URLSearchParams(window.location.search);
    const errorText = params.get("errorText");
    const errorCode = params.get("errorCode");
    if (errorText || errorCode) {
      location.replace(
        `${location.origin}/${location.hash}?errorText=${errorText}&errorCode=${errorCode}`
      );
    }
  }

  created() {
    if (localStorage.getItem("businessContext")) {
      this.setBusinessContext(
        JSON.parse(
          localStorage.getItem("businessContext") as string
        ) as PublicBusinessContext
      );
    }
    this.loadBusinessContext().then(() => (this.loading = false));
  }
  @Watch("isAppIdle")
  async onAppIdle(isAppIdle: boolean): Promise<void> {
    if (isAppIdle && this.$route.meta && !this.$route.meta.notAuthZone) {
      await this.logout();
      console.log("IDLE TIMEOUT");
    }
  }
}
