import { ActionContext } from "vuex";
import router from "@/router";
import { AuthStorage, IConfirmGetTokenResponse } from "@/data/auth";
import ProfileResource from "@/resources/profile-resource";
import { Profile } from "@/data/profile";
import WalletResource from "@/resources/wallet";

export class User {
  sub: string = "";
  name: string = "";
  role: string = "";
  blockedAt: boolean | undefined;
  confirmedAt: boolean | undefined;

  constructor(user?: User) {
    console.log(user);
    this.sub = (user && user.sub) || "";
    this.name = (user && user.name) || "";
    this.role = (user && user.role) || "";
    this.blockedAt = user && user.blockedAt;
    this.confirmedAt = user && user.confirmedAt;
  }
}

class AccountState {
  account: null | User = null;
  profile: null | Profile = null;

  constructor() {
    if (AuthStorage.getAccessToken()) {
      this.account = new User(AuthStorage.getTokenInfo());
    }
  }

  clearState() {
    this.account = null;
    this.profile = null;
  }

  setProfile(payload: any) {
    this.profile = new Profile(payload);
  }

  updateState(payload: any) {
    this.account = new User(payload);
  }
}

export class AccountModule {
  public state = new AccountState();

  public getters = {
    isAccountBanned(state: AccountState) {
      const { account } = state;
      return account && account.blockedAt;
    },
    isAccountActive(state: AccountState) {
      const { account } = state;
      return account && !account.blockedAt && account.confirmedAt;
    }
  };

  public mutations = {
    login: (state: AccountState, payload: IConfirmGetTokenResponse) => {
      AuthStorage.store(payload);
      state.updateState(AuthStorage.getTokenInfo());
    },
    logout: (state: AccountState) => {
      AuthStorage.clear();
      state.clearState();
    },
    setAccount: (state: AccountState) => {
      state.updateState(AuthStorage.getTokenInfo());
    },
    setProfile: (state: AccountState, payload: any) => {
      state.setProfile(payload);
    },
    updateAccount(state: AccountState, payload: User) {
      if (state.account) {
        const { account } = state;
        account.confirmedAt = payload.confirmedAt;
        account.blockedAt = payload.blockedAt;
      }
    }
  };

  public actions = {
    async setProfile(context: ActionContext<AccountState, any>) {
      let promise = await ProfileResource.load();
      context.commit("setProfile", promise);
    },
    async login(context: ActionContext<AccountState, any>, payload: any) {
      await context.commit("login", payload);
      if (context.getters.isAccountBanned || !context.getters.isAccountActive) {
        router.push({
          name: "profile"
        });
      } else {
        router.push({
          name: "wallet-operations-list"
        });
      }
    },
    logout(context: ActionContext<AccountState, any>) {
      context.commit("logout");
      router.push({
        name: "login"
      });
    },
    setAccount(
      context: ActionContext<AccountState, any>,
      payload: IConfirmGetTokenResponse
    ) {
      AuthStorage.store(payload);
      context.commit("setAccount");
    },
    async updateAccount(
      context: ActionContext<AccountState, any>
    ): Promise<void> {
      await WalletResource.loadUser().then(user => {
        context.commit("updateAccount", user);
      });
    }
  };
}
