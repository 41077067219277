import Vue from "vue";
import Vuex from "vuex";
import {
  ConfimModule,
  Confirm,
  Entity,
  EntityModule,
  Layout,
  LayoutModule,
  Loading,
  LoadingModule,
  Locale,
  LocaleModule,
  LocaleMutationPayload,
  Notification,
  NotificationModule,
  Path,
  PathModule
} from "eka.core";
import { AccountModule } from "@/store/modules/account";
import { WalletModule } from "@/store/modules/wallet";
import { BusinessContextModule } from "@/store/modules/business-context-module";

Vue.use(Vuex);
class CommonState {}
export default new Vuex.Store({
  state: {},
  getters: {
    copyright: (state: CommonState) => {
      return "";
    }
  },
  mutations: {
    [Locale.Locale]: (state: CommonState, mutation: LocaleMutationPayload) => {
      //@ts-ignore
      state.locale.locale = mutation.payload;
      (window as any).app.$i18n.locale = mutation.payload;
    }
  },
  modules: {
    [Layout.layout]: new LayoutModule(),
    [Notification.notifications]: new NotificationModule(),
    [Loading.loadings]: new LoadingModule(),
    [Confirm.Confirm]: new ConfimModule(),
    [Path.ReturnPath]: new PathModule(),
    [Locale.Locale]: new LocaleModule(),
    [Entity.Entity]: new EntityModule(),
    account: new AccountModule(),
    wallet: new WalletModule(),
    businessContext: new BusinessContextModule()
  }
});
