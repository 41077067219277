














import { Component, Vue, Watch } from "vue-property-decorator";
import sidebar from "./sidebar.vue";
import userDataHeader from "./user-data-header.vue";

@Component({
  components: {
    userDataHeader,
    sidebar
  }
})
export default class BaseLayout extends Vue {
  @Watch("isAppIdle")
  async appIdle(newVal: boolean, oldVal: boolean) {
    if (newVal) {
      // todo
      // await AuthResource.logout();
    }
  }

  get sidebar() {
    return this.$store.state.layout.sidebar;
  }

  get locale(): string {
    return this.$store.state.locale.locale;
  }

  created() {
    window.addEventListener("resize", this.checkWidth);
  }

  checkWidth(): boolean {
    if (window.innerWidth >= 1280) {
      // this.className = ""; // todo: 1280 взято из дизайнерского скрипта, безусловно, над этим надо подумать
      return true;
    }
    return false;
  }
  toggle() {
    if (this.checkWidth()) {
      return;
    }
    $(".vs-sticky-top").each((index, element) => {
      if (this.sidebar === "in-shadow") $(element).removeClass("sticky-top");
      else $(element).addClass("sticky-top");
    });
  }
}
