import Vue from "vue";
import VueRouter, { NavigationGuardNext } from "vue-router";
import { hideTooltips, NotFoundComponent } from "eka.core";
import store from "@/store";
import { AuthStorage } from "@/data/auth";

Vue.use(VueRouter);

//@ts-ignore
const router = new VueRouter({
  routes: [
    {
      path: "/",
      beforeEnter: (to, from, next) => {
        return next({ name: "login" });
      }
    },
    {
      path: "/auth",
      component: () => import("../pages/Auth/Auth.vue"),
      beforeEnter: (to, from, next) => {
        if (AuthStorage.getAccessToken()) {
          next({ path: "/wallet-operations-list" });
        } else {
          next();
        }
      },
      children: [
        {
          path: "/",
          name: "login",
          component: () => import("../pages/Auth/Login.vue"),
          meta: { notAuthZone: true }
        },
        {
          path: "registration",
          name: "registration",
          component: () => import("../pages/Auth/Registration.vue"),
          meta: { notAuthZone: true }
        },
        {
          path: "confirm",
          name: "confirm",
          component: () => import("../pages/Auth/Confirm.vue"),
          meta: { notAuthZone: true }
        },
        {
          path: "password",
          name: "password",
          component: () => import("../pages/Auth/EnterPassword.vue"),
          meta: { notAuthZone: true }
        },
        {
          path: "password/new",
          name: "password-new",
          component: () => import("../pages/Auth/Password.vue"),
          meta: { notAuthZone: true }
        },
        {
          path: "password/restore",
          name: "passwordRestore",
          component: () => import("../pages/Auth/Password.vue"),
          meta: { notAuthZone: true }
        },
        {
          path: "restore",
          name: "restore",
          component: () => import("../pages/Auth/RestorePassword.vue"),
          meta: { notAuthZone: true }
        },
        {
          path: "restore/confirm",
          name: "confirm_restore",
          component: () => import("../pages/Auth/Confirm.vue"),
          meta: { notAuthZone: true }
        },
        {
          path: "passport",
          name: "passport",
          component: () => import("../pages/Auth/Passport.vue"),
          meta: { notAuthZone: true }
        }
      ],
      meta: { notAuthZone: true }
    },
    {
      path: "/wallet",
      component: () => import("../pages/WalletView.vue"),
      children: [
        {
          path: "operations",
          name: "wallet-operations-list",
          component: () => import("../pages/Operations/OperationsListView.vue")
        },
        {
          path: "replenishment",
          name: "replenishment-form",
          component: () =>
            import("../pages/Replenishment/ReplenishmentView.vue")
        },
        {
          path: "write-off",
          name: "write-off-form",
          component: () => import("../pages/WriteOffs/WriteOffsView.vue")
        },
        {
          path: "/profile",
          name: "profile",
          component: () => import("../pages/Profile/ProfileView.vue")
        }
      ]
    },
    {
      path: "/invoice/",
      name: "invoice",
      component: () => import("../pages/Invoice/InvoiceCreationView.vue")
    },
    {
      path: "/invoice-confirm/:code",
      name: "invoice-confirm",
      component: () => import("../pages/Invoice/InvoiceSendView.vue")
    },
    {
      path: "/invoice-payment/:code",
      name: "invoice-payment",
      component: () => import("../pages/Invoice/InvoicePaymentView.vue"),
      meta: { notAuthZone: true, direct: true }
    },
    {
      path: "/invoice-redirect/:code",
      name: "invoice-redirect",
      component: () => import("../pages/Invoice/InvoiceRedirectView.vue"),
      meta: { notAuthZone: true, direct: true }
    },
    {
      path: "/invoice-success/:code",
      name: "invoice-success",
      component: () => import("../pages/Invoice/InvoiceSuccessView.vue"),
      meta: { notAuthZone: true, direct: true }
    },
    {
      path: "/user-confirm",
      name: "user-confirm",
      component: () => import("../pages/Officer/UserConfirmedView.vue"),
      meta: {
        notAuthZone: true,
        direct: true
      }
    },
    {
      path: "/user-decline",
      name: "user-decline",
      component: () => import("../pages/Officer/UserDeclinedView.vue"),
      meta: {
        notAuthZone: true,
        direct: true
      }
    },
    {
      path: "/payment-confirm",
      name: "payment-confirm",
      component: () => import("../pages/Officer/PaymentConfirmView.vue"),
      meta: {
        notAuthZone: true,
        direct: true
      }
    },
    {
      path: "/payment-decline",
      name: "payment-decline",
      component: () => import("../pages/Officer/PaymentDeclineView.vue"),
      meta: {
        notAuthZone: true,
        direct: true
      }
    },
    {
      path: "*",
      name: "NotFound",
      component: NotFoundComponent,
      meta: { notAuthZone: true, direct: true }
    }
  ]
});
router.beforeEach(async (to: any, from: any, next: any) => {
  if (to.meta.notAuthZone) {
    if (to.meta.direct) {
      return next();
    }

    if (localStorage.getItem("accessToken")) {
      return next({
        name: "wallet-operations-list"
      });
    } else {
      return next();
    }
  } else {
    if (!localStorage.getItem("accessToken")) {
      return next({
        name: "login"
      });
    } else {
      await store.dispatch("updateAccount");
      if (!["profile"].includes(to.name)) {
        if (store.getters.isAccountBanned || !store.getters.isAccountActive) {
          return next("profile");
        }
      }
      if (from.meta.notAuthZone || from.path === "/") {
        await store.dispatch("setProfile");
      }
      return next();
    }
  }
});

router.afterEach((to: any, from: any) => {
  hideTooltips();
  // Прокручиваем в начало страницы
  window.scrollTo(0, 0);
});

export default router;
