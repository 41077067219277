





import { Component, Vue } from "vue-property-decorator";
import { getCommonStore, Option, LocaleMutationPayload } from "eka.core";

@Component
export default class UserLang extends Vue {
  languages: Option[] = [
    { value: "ru", label: "RU" },
    { value: "en", label: "EN" }
  ];
  selectedLang: string | null = null;

  get businessContext(): null {
    return null;
    //this.$store.state.businessContext;
  }

  get locale(): string {
    return this.$store.state.locale.locale;
  }

  get locales() {
    return false;
  }

  getLocales() {
    this.selectedLang = this.locale ? this.locale : this.selectedLang;
  }
  async setLocale(locale: string): Promise<void> {
    try {
      // await UsersResource.updateUserLocale(locale);
    } finally {
      this.$store.commit("locale", new LocaleMutationPayload(locale));
    }
  }

  created() {
    this.getLocales();
  }
}
