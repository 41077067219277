//@ts-ignore
import * as Fingerprint2 from "fingerprintjs2";
//@ts-ignore
import * as UAParser from "ua-parser-js";
//@ts-ignore
import jwt_decode from "jwt-decode";
import { getCommonStore } from "eka.core";
import store from "@/store";
import {
  NotifyMutationPayload,
  NotificationType,
  INotification
} from "eka.core";
import AuthResource from "@/resources/auth-resource";

export enum AuthAction {
  confirmAuthentication = "confirmAuthentication",
  register = "register",
  setPassword = "setPassword",
  enterPassword = "enterPassword",
  setPassport = "setPassport",
  confirmPasswordRestoring = "confirmPasswordRestoring",
  requestPasswordRestoring = "requestPasswordRestoring",
  proceedPasswordRestoring = "proceedPasswordRestoring",
  enterWithEmail = "enterWithEmail",
  enterWithEmailFinish = "enterWithEmailFinish",
  verifyCode = "verifyCode"
}

export class Passport {
  lastName: string;
  firstName: string;
  middleName: string;
  series: string;
  number: string;
  issueDate: string;
  departmentCode: string;
  issuedBy: string;
  registrationAddress: string;
  snils: string;
  activityType: string;
  inn: string;
  email: string;

  constructor(passport?: Passport) {
    this.lastName = (passport && passport.lastName) || "";
    this.firstName = (passport && passport.firstName) || "";
    this.middleName = (passport && passport.middleName) || "";
    this.series = (passport && passport.series) || "";
    this.number = (passport && passport.number) || "";
    this.issueDate = (passport && passport.issueDate) || "";
    this.departmentCode = (passport && passport.departmentCode) || "";
    this.issuedBy = (passport && passport.issuedBy) || "";
    this.registrationAddress = (passport && passport.registrationAddress) || "";
    this.snils = (passport && passport.snils) || "";
    this.activityType = (passport && passport.activityType) || "";
    this.inn = (passport && passport.inn) || "";
    this.email = (passport && passport.email) || "";
  }
}

export interface IConfirmGetTokenResponse {
  accessToken: string;
  refreshToken: string;
}
export interface IUpdateTokenRequest {
  deviceFingerprint: string;
  refreshToken: string;
}
export interface IAuthRequest {
  phone?: string;
  smsConfirmationToken?: string;
  smsConfirmationCode?: string;
  password?: string;
  passport?: Passport;
  deviceFingerprint?: string;
  action?: AuthAction | string;
}

export interface IAuthResponse {
  action: AuthAction;
  smsConfirmationToken: string;
  requiredProperties: AuthFormFields[];
  accessToken?: string;
  refreshToken?: string;
}

export enum AuthFormFields {
  phone = "phone",
  smsConfirmationToken = "smsConfirmationToken",
  smsConfirmationCode = "smsConfirmationCode",
  action = "action",
  password = "password",
  passport = "passport",
  deviceFingerprint = "deviceFingerprint",
  email = "email"
}

export type AuthFormKey = keyof AuthForm;

export class AuthForm {
  phone = "";
  smsConfirmationToken = "";
  smsConfirmationCode = "";
  password = "";
  passwordConfirm = "";
  passport: Passport = new Passport();
  action: AuthAction | string = "";
  accessToken = "";
  refreshToken = "";

  // retry confirm request
  prevRequest: IAuthRequest | null = null;

  requiredProperties: AuthFormFields[] = [];

  async phoneAuthRequest(): Promise<IAuthRequest> {
    return {
      phone: this.phone,
      deviceFingerprint: await AuthStorage.getFingerPrint()
    };
  }

  async passwordRestoreRequest(): Promise<IAuthRequest> {
    return {
      ...(await this.phoneAuthRequest()),
      action: AuthAction.requestPasswordRestoring
    };
  }

  async authRequest(): Promise<IAuthRequest> {
    const request = {} as IAuthRequest;

    if (this.requiredProperties.includes(AuthFormFields.deviceFingerprint)) {
      request.deviceFingerprint = await AuthStorage.getFingerPrint();
    }

    if (this.smsConfirmationToken) {
      request.smsConfirmationToken = this.smsConfirmationToken;
    }

    for (const i of this.requiredProperties) {
      if (this.hasOwnProperty(i)) {
        // @ts-ignore
        request[i] = this[i as AuthFormKey];
      }
    }

    request.action = this.action;

    return request;
  }

  async submit(request?: IAuthRequest): Promise<string> {
    request = request ? request : await this.authRequest();

    return await AuthResource.authentication(request).then(res => {
      return this.onAuthResponse(res);
    });
  }

  onAuthResponse(response: IAuthResponse): string {
    if (response.accessToken && response.refreshToken) {
      this.accessToken = response.accessToken;
      this.refreshToken = response.refreshToken;

      store.dispatch("login", {
        accessToken: response.accessToken,
        refreshToken: response.refreshToken
      });
      throw new Error("Success auth");
    }
    if (response.smsConfirmationToken) {
      this.smsConfirmationToken = response.smsConfirmationToken;
    }

    this.action = response.action;
    this.requiredProperties = response.requiredProperties;

    return this.getNextRouteOnResponse();
  }

  getNextRouteOnResponse() {
    switch (this.action) {
      case AuthAction.enterWithEmail:
      case AuthAction.confirmAuthentication:
        return "password";
      case AuthAction.register:
      case AuthAction.enterWithEmailFinish:
      case AuthAction.requestPasswordRestoring:
      case AuthAction.verifyCode:
        return "confirm";
      case AuthAction.proceedPasswordRestoring:
        return "confirm_restore";
      case AuthAction.setPassport:
        return "passport";
      case AuthAction.enterPassword:
        return "password";
      case AuthAction.setPassword:
        return "password-new";
      case AuthAction.confirmPasswordRestoring:
        return "passwordRestore";
    }
    return "";
  }
}

export class AuthStorage {
  static getFingerPrint(): Promise<string> {
    return new Promise((resolve, reject) => {
      async function getHash() {
        const options = {
          excludes: {
            plugins: true,
            localStorage: true,
            adBlock: true,
            screenResolution: true,
            availableScreenResolution: true,
            enumerateDevices: true,
            pixelRatio: true,
            doNotTrack: true
          },
          preprocessor: (key: string, value: string) => {
            if (key === "userAgent") {
              const parser = new UAParser(value);
              // return customized user agent (without browser version)
              return `${parser.getOS().name} :: ${
                parser.getBrowser().name
              } :: ${parser.getEngine().name}`;
            }
            return value;
          }
        };

        try {
          const components = await Fingerprint2.getPromise(options);
          const values = components.map((component: any) => component.value);
          return String(Fingerprint2.x64hash128(values.join(""), 31));
        } catch (e) {
          reject(e);
        }
      }

      if ((window as any).requestIdleCallback) {
        (window as any).requestIdleCallback(async () =>
          resolve(await getHash())
        );
      } else {
        setTimeout(async () => resolve(await getHash()), 500);
      }
    });
  }
  public static getAccessToken() {
    return localStorage.getItem("accessToken") &&
      localStorage.getItem("accessToken") !== "undefined"
      ? localStorage.getItem("accessToken")
      : null;
  }

  public static getRefreshToken() {
    return localStorage.getItem("refreshToken") || null;
  }
  static store(payload: IConfirmGetTokenResponse) {
    let { accessToken, refreshToken } = payload;
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("refreshToken", refreshToken);
  }

  static clear() {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
  }

  static getTokenInfo(): any {
    return AuthStorage.getAccessToken()
      ? jwt_decode(AuthStorage.getAccessToken())
      : null;
  }
  static async isTokenExp(): Promise<boolean> {
    if (!AuthStorage.getAccessToken()) {
      return true;
    }
    let token = await AuthStorage.getTokenInfo();
    if (!token || !token.exp) {
      return true;
    }
    let exp = +`${token.exp}000` - 60000;
    return exp < new Date().getTime();
  }

  static async updateTokenRequest(): Promise<IUpdateTokenRequest> {
    return {
      refreshToken: AuthStorage.getRefreshToken() as string,
      deviceFingerprint: await AuthStorage.getFingerPrint()
    };
  }

  static _updatePromise: Promise<any> | null = null;
  static updateTokens() {
    if (this._updatePromise) return this._updatePromise;
    let promise = AuthResource.refreshToken();

    promise.then(async (data: IConfirmGetTokenResponse) => {
      await getCommonStore().dispatch("setAccount", data);
      this._updatePromise = null;
      return Promise.resolve(data);
    });

    promise.catch(async () => {
      await getCommonStore().dispatch("logout");
      getCommonStore().commit(
        new NotifyMutationPayload({
          message: "Пройдите авторизацию повторно",
          type: NotificationType.error,
          canClose: true,
          duration: 3000
        } as INotification)
      );
      this._updatePromise = null;
      return Promise.reject(new Error("Ошибка авторизации"));
    });

    this._updatePromise = promise;

    return promise;
  }
}
